// Add your custom JS here.
jQuery(window).scroll(function () {
    if (jQuery(this).scrollTop() > 50) {
        jQuery('header.header-site').addClass('scrolled');
    } else {
        jQuery('header.header-site').removeClass('scrolled');
    }
});


 /*skip content*/
document.addEventListener('DOMContentLoaded', function () {
    const LinkGoSecondSection = document.getElementById('GoSecondSection');
    LinkGoSecondSection.addEventListener('click', function (event) {
        event.preventDefault();
        const secondSection = document.querySelector('.general-section:nth-of-type(2)');
        if (secondSection) {
            secondSection.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('the second section was not found.');
        }
    });
});


// Detect if the user is navigating with keyboard
function handleFirstTab(e) {
    if (e.key === 'Tab') {
        document.body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
}

// Detect if the user switches to using the mouse
function handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing');
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
}

// Apply focus styles based on keyboard navigation
document.addEventListener('focusin', function(e) {
    if (document.body.classList.contains('user-is-tabbing')) {
        e.target.classList.add('keyboard-focus');
    }
});

document.addEventListener('focusout', function(e) {
    e.target.classList.remove('keyboard-focus');
});

window.addEventListener('keydown', handleFirstTab);
